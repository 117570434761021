<template>
  <b-container>
    <div class="sub-users">
      <div class="d-flex align-items-center mt-2 mb-2 px-3">
        <h4 class="mr-auto">Tài khoản phụ</h4>
        <b-button size="sm" variant="primary" @click="onCreate">
          <b-icon icon="person-plus"></b-icon>
          Tạo
        </b-button>
      </div>
      <b-card class="mb-2 card-border-top">
        <div class="d-flex flex-column mt-2 mb-2">
          <b-table :fields="fields" :items="items" borderless>
            <template #cell(action)="row">
              <b-button-group>
                <b-button
                  variant="outline-primary"
                  size="sm"
                  @click="onEdit(row.item)"
                  >Sửa</b-button
                >
                <b-button
                  variant="danger"
                  size="sm"
                  @click="onDelete(row.item.id)"
                >
                  <b-icon icon="trash"></b-icon>
                </b-button>
              </b-button-group>
            </template>
          </b-table>
        </div>
      </b-card>
      <b-modal v-model="modalShow">
        <div>
          <b-form-group label="Tên đăng nhập" label-for="username">
            <b-form-input
              disabled
              id="username"
              v-model="username"
              trim
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Mật khẩu" label-for="password">
            <b-form-input
              id="password"
              type="password"
              v-model="password"
              trim
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Ghi chú" label-for="note">
            <b-form-input id="note" v-model="note" trim></b-form-input>
          </b-form-group>
        </div>
        <template #modal-footer>
          <div class="d-flex justify-content-end">
            <b-button variant="success" @click="onUpdateUser"
              >{{ mode == "create" ? "Tạo" : "Sửa" }} tài khoản</b-button
            >
          </div>
        </template>
      </b-modal>
    </div>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      fields: [
        {
          key: "name",
          label: "Tên đăng nhập",
          formatter: "usernameWithNote",
          class: "text-left"
        },
        { key: "action", label: "", class: "text-right" }
      ],
      items: [],
      modalShow: false,
      username: "",
      password: "",
      note: "",
      mode: "create",
      editId: null
    };
  },
  computed: {
    ...mapGetters(["currentUser"])
  },
  methods: {
    async fetchSubUsers() {
      let res = await this.$http.get("/user/subs");
      if (res.data.code == 0) {
        this.items = [...res.data.data];
      }
    },
    usernameWithNote(value, key, u) {
      return `${u.name} (${u.note})`;
    },
    onCreate() {
      this.bindNewUsername();
      this.modalShow = true;
      this.mode = "create";
    },
    onEdit(u) {
      this.username = u.name;
      this.note = u.note;
      this.modalShow = true;
      this.mode = "edit";
      this.editId = u.id;
    },
    async onDelete(uid) {
      let res = await this.$http.delete("/user/sub/" + uid);
      if (res.data.code == 0) {
        this.fetchSubUsers();
      }
    },
    bindNewUsername() {
      let total = this.items.length;
      total++;
      total = total >= 0 && total <= 9 ? "0" + total : total;
      this.username = this.currentUser.name + "s" + total;
    },
    async onUpdateUser() {
      if (this.password == "") {
        this.makeToast("Lỗi", "Mật khẩu không được để trống", "danger", true);
        return;
      }
      let url = this.mode == "edit" ? `/user/sub/${this.editId}` : `/user/sub`;
      let data = { password: this.password, note: this.note };
      let req = this.mode == "edit" ? this.$http.put : this.$http.post;
      let res = await req(url, data);
      if (res.data.code == 0) {
        this.fetchSubUsers();
        this.modalShow = false;
      } else {
        this.makeToast("Lỗi", res.data.msg, "danger", true);
      }
    },
    makeToast(title, content, variant, append = false) {
      this.$bvToast.toast(content, {
        title: title,
        autoHideDelay: 5000,
        appendToast: append,
        variant: variant,
        solid: true,
        toaster: "b-toaster-bottom-right mb-5"
      });
    }
  },
  created() {
    this.fetchSubUsers();
  }
};
</script>

<style>
.search-box {
  border: 1px solid #eee;
  margin: 0;
  padding: 0 15px;
}
.search-form {
  border: none;
}
.d-sm-none.search-box {
  border-left: none;
  border-right: none;
}
</style>
